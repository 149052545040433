import request from '@/utils/request';
import { stringify } from 'querystring';

// 获取查询报餐配置
export function getReportMealConfig() {
  return request({
    url: '/mealReporting/h5/select',
    method: 'get'
  });
}

// 提交报餐申请
export function postReportMeal(data) {
  return request({
    url: '/mealReporting/h5/request',
    method: 'post',
    data
  });
}

// 提交报餐申请-预付费用
export function postReportMealPay(data) {
  return request({
    url: '/mealReporting/h5/requestMoney',
    method: 'post',
    data
  });
}

// 获取报餐申请列表
export function getList() {
  return request({
    url: '/mealReporting/h5/slectList',
    method: 'get'
  });
}
// 取消报餐
export function cancelApply(params) {
  return request({
    url: `/mealReporting/h5/cancle?${stringify(params)}`,
    method: 'get'
  });
}
